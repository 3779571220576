import React from 'react';
import Home from './Home';
import Layout from '../components/layout';
import SEO from '../components/seo';

const IndexPage = () => (
	<Layout>
		<SEO title='Home' />
		<Home />
	</Layout>
);

export default IndexPage;
